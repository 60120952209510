<template>
  <div>
    <div id="map"></div>
    <a-modal
      width="850px"
      class="geographic-unit-modal"
      :dialog-style="{ display: 'none' }"
      :footer="null"
      :closable="true"
      :centered="true"
      :visible="active"
      @cancel="onCancel">
      <h3 class="flex align-center txt-32 txt-bold">
        Add Geographical Unit Location
      </h3>
      <p>
        Enter the latitude and longitude or click the area on
        the map that best represents the geographical location.
      </p>
      <a-input
        v-model="searchText"
        size="large"
        placeholder="Search a location"
        allow-clear
        class="w100 mb-20"
        @change="search"/>
      <div class="search-container" :class="{ hasResults: searchResults.length > 0 }">
        <div class="search-results">
          <div
            v-for="result of searchResults"
            :key="result.placeId"
            class="search-result"
            @click="setPayload(result)">
            <a-icon type="environment" class="mr-8"/>
            {{ result.description }}
          </div>
        </div>
        <div class="map-container">
          <div class="controls flex align-center">
            <a-input v-model.number="payload.lat" size="large" placeholder="Latitude" class="mr-16 w200" @blur="onBlur"/>
            <a-input v-model.number="payload.lng" size="large" placeholder="Longitude" class="w200" @blur="onBlur"/>
          </div>
          <l-map
            ref="myMap"
            class="map"
            :zoom="zoom"
            :center="center"
            @ready="ready"
            @update:center="centerUpdate"
            @update:zoom="zoomUpdate"
            @click="onClick">
            <v-tilelayer-googlemutant
              :apikey="GOOGLE_MAPS_API_KEY"
              lang="en"
              region="en"/>
            <l-marker :draggable="true" :lat-lng="marker" :icon="icon" @update:latLng="onMove"/>
          </l-map>
        </div>
      </div>
      <div class="flex justify-space-between w100">
        <a-button
          class="mt-24 w170"
          type="primary" ghost
          size="large"
          @click="onCancel">
          Cancel
        </a-button>
        <a-button
          class="mt-24 w170"
          type="primary"
          size="large"
          @click="save">
          Save
        </a-button>
      </div>
    </a-modal>
  </div>
</template>

<script>
import debounce from 'lodash/debounce';
import L, { latLng } from 'leaflet';
import { LMap, LMarker } from 'vue2-leaflet';
import Vue2LeafletGoogleMutant from 'vue2-leaflet-googlemutant';
import 'leaflet/dist/leaflet.css';
import { GOOGLE_MAPS_API_KEY } from '../../../util/map';

export default {
  name: 'GeographicUnitModal',
  components: {
    LMap,
    LMarker,
    'v-tilelayer-googlemutant': Vue2LeafletGoogleMutant
  },
  props: {
    initialLat: {
      type: Number,
      default: -1.286389
    },
    initialLng: {
      type: Number,
      default: 36.817223
    },
    active: {
      type: Boolean,
      required: true
    },
    onExit: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      GOOGLE_MAPS_API_KEY,
      searchText: '',
      searchResults: [],
      payload: {
        lat: undefined,
        lng: undefined
      },
      sessionToken: undefined,
      map: undefined,
      icon: L.icon({
        iconUrl: '/marker.png',
        iconSize: [32, 37],
        iconAnchor: [16, 20]
      }),
      zoom: 9,
      center: latLng(this.initialLat, this.initialLng),
      url: 'https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png',
      marker: latLng(this.initialLat, this.initialLng)
    };
  },
  created() {
    if (this.initialLat && this.initialLng) {
      this.reset();
    }
  },
  mounted() {
    // eslint-disable-next-line no-undef
    this.sessionToken = new google.maps.places.AutocompleteSessionToken();
    // eslint-disable-next-line no-undef
    this.map = new google.maps.Map(document.getElementById('map'), {
      center: { lat: -33.8666, lng: 151.1958 },
      zoom: 15
    });
  },
  methods: {
    zoomUpdate(zoom) {
      this.currentZoom = zoom;
    },
    centerUpdate(center) {
      this.currentCenter = center;
    },
    ready() {
      this.$refs.myMap.mapObject._onResize();
    },
    onMove({ lat, lng }) {
      this.payload.lat = lat;
      this.payload.lng = lng;
    },
    save() {
      this.onExit({ ...this.payload });
    },
    onBlur() {
      if (this.payload.lat && this.payload.lng) {
        const payloadLatLng = latLng(this.payload.lat, this.payload.lng);
        this.center = payloadLatLng;
        this.marker = payloadLatLng;
      }
    },
    onCancel() {
      this.onExit();
    },
    exit() {
      if (this.initialLat && this.initialLng) {
        this.onExit({
          lat: this.initialLat,
          lng: this.initialLng
        });
      }
    },
    onClick(ev) {
      this.marker = ev.latlng;
      const { lat, lng } = ev.latlng;
      this.payload.lat = lat;
      this.payload.lng = lng;
    },
    async getLocation(prediction) {
      // eslint-disable-next-line no-undef
      const service = new google.maps.places.PlacesService(this.map);
      return new Promise((resolve, reject) => {
        service.getDetails({
          placeId: prediction,
          fields: ['name', 'geometry', 'address_components']
        },
        (result) => resolve(result), (err) => reject(err));
      });
    },
    async setResults(predictions) {
      if (!predictions || (predictions && Array.isArray(predictions) && predictions.length === 0)) {
        return;
      }
      // eslint-disable-next-line no-undef
      const predictionLocations = await Promise.all(
        predictions.map(prediction => this.getLocation(prediction.place_id))
      );
      this.searchResults = predictions.map((prediction, index) => ({
        ...prediction,
        address_components: predictionLocations[index].address_components,
        lat: predictionLocations[index].geometry.location.lat(),
        lng: predictionLocations[index].geometry.location.lng()
      }));
      this.$refs.myMap.mapObject._onResize();
    },
    reset() {
      this.center = latLng(this.initialLat, this.initialLng);
      this.payload.lat = this.initialLat;
      this.payload.lng = this.initialLng;
      this.searchResults = [];
    },
    // eslint-disable-next-line func-names
    search: debounce(function(event) {
      if (this.searchText === '') {
        this.reset();
        this.$refs.myMap.mapObject._onResize();
        return;
      }
      // eslint-disable-next-line no-undef
      const autocompleteService = new google.maps.places.AutocompleteService();
      autocompleteService.getPlacePredictions({
        input: event.target.value,
        sessionToken: this.sessionToken
      }, this.setResults);
    }, 500),
    setPayload(result) {
      this.payload.lat = result.lat;
      this.payload.lng = result.lng;
      const payloadLatLng = latLng(result.lat, result.lng);
      this.center = payloadLatLng;
      this.marker = payloadLatLng;
    }
  }
};
</script>

<style lang="scss" scoped>
.search-container {
  display: grid;
  grid-template-columns: 0% 100%;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  grid-template-areas:
    ". .";

  &.hasResults {
    grid-template-columns: 40% 60%;
  }
}

.search-results {
  border-left: solid 1px #cccccc;
  border-top: solid 1px #cccccc;
  border-bottom: solid 1px #cccccc;
  max-height: 375px;
  overflow: auto;
}

.search-result {
  padding: 20px;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
  display: flex;
  align-items: center;
  min-height: 60px;
  &:hover {
    background: #ccc;
  }
}

.map-container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 6fr;
  gap: 0px 0px;
  grid-template-areas:
    "."
    ".";
  border: solid 1px #cccccc;
  background: #f4f4f4;
  height: 375px;
}

.controls {
  padding: 16px;
}

.map {
  border-top: solid 1px #cccccc;
  overflow: auto;
}
</style>
